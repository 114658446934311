<template>
    <div class="no-content-container">
        <inline-svg class="cta-icon" :src="data.icon"></inline-svg>
        <p class="message">{{data.message}}</p>
    </div>
</template>
<script>
import { useRouter } from 'vue-router';

export default {
  props: ['data'],
  setup() {
    const router = useRouter();

    function routeToAddPropertyForm() {
      router.push({
        name: 'select-property',
      });
    }

    return {
      routeToAddPropertyForm
    };
  }
};
</script>
<style lang="scss" scoped>
.no-content-container {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100%;

    .message {
        font-family: Mulish;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.4000000059604645px;
        color: rgba(12, 15, 74, 0.5);
        text-align:center;
    }

    .white-button {
        background: #FFF;
        border-radius: 7px;
        color: #4F55F0;
        outline: none;
        border: none;
        width: 176px;
        height: 42px;
        box-shadow: 0px 4px 20px rgba(42, 42, 42, 0.1);
    }
}
</style>
